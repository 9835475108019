<template>
  <div>
    <CampusHeader
        :can-select-year="true"
        :can-create="false"
        :activeTab="0"
        :manage-campuses="true"
        :hide-tabs="true"
        :header-text="'HomeClasses'"
        :header_tabs="[{ title: `HomeClasses` }]"
    ></CampusHeader>
    <section class="section pt-4 box">
      <b-tabs expanded multiline  class="hidden-tabs" v-model="activeTab">
        <b-tab-item>
          <div class="columns">
            <div class="column">
              <ClassListTable>

              </ClassListTable>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import ClassListTable from "@/components/classLists/ClassListTable";


export default {
  name: "Classlists.index",
  components: {
    CampusHeader,
    ClassListTable
  },
  data() {
    return {

      activeTab: 0,
    };
  },
  methods: {


    changeTab(index) {
      this.activeTab = index;
    },
  },

  mounted() {
  },
  computed: {},
};
</script>

