<template>
  <div v-if="loaded">

    <PerfectScrollbarWrapper>
      <b-table
          ref="table"
          :bordered="false"
          :data="classes"
          :hoverable="true"
          :loading="loadingData"
          :striped="true"
          class="margin-top is-clickable is-fullwidth"
          @click="viewLearners"

      >
        <template #empty>
          <div class="has-text-centered">No class lists</div>
        </template>
        <b-table-column
            v-slot="props"
            field="id"
            label="ID"
            numeric
            sortable
            width="40"
        >{{ props.row.id }}
        </b-table-column>
        <b-table-column
            v-slot="props"
            field="name"
            label="Name"
            sortable

        >{{ props.row.name }}
        </b-table-column>

        <b-table-column
            v-slot="props"
            centered
            custom-key="actions"
            label="Actions"

        >
          <div v-on:click.stop>
            <b-dropdown append-to-body aria-role="list" position="is-bottom-left">
              <template #trigger>
                <b-icon
                    :icon="$tc('icons.more')"/>
              </template>
              <b-dropdown-item aria-role="listitem"

                               @click="downloadPdf(props.row)">Download
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-table-column>


      </b-table>
    </PerfectScrollbarWrapper>
    <b-modal v-model="modal_open">


      <div class="modal-card" style="width: auto">
          <header  class="modal-card-head">
            <p class="modal-card-title">{{$tc('Learner',2)}}</p>
          </header>
        <section class="modal-card-body">
          <perfect-scrollbar-wrapper>
          <b-table :bordered="false"
                   :data="activeLearners"
                   :hoverable="true"
                   :striped="true" class="is-clickable"
                   @click="showLearner">
            <template #empty>
              <div class="has-text-centered">No {{ $tc('learner', 2) }}</div>
            </template>
            <b-table-column
                v-slot="props"
                field="id"
                label="ID"
                numeric
                sortable
                width="40"
            >{{ props.row.id }}
            </b-table-column>

            <b-table-column
                v-slot="props"
                field="first_name"
                label="First Name"
                sortable
            >{{ props.row.first_name }}
            </b-table-column>

            <b-table-column
                v-slot="props"
                field="last_name"
                label="Last Name"
                sortable
            >{{ props.row.last_name }}
            </b-table-column>
            <b-table-column v-slot="props" field="email" label="Email" sortable>{{
                props.row.email
              }}
            </b-table-column>
          </b-table></perfect-scrollbar-wrapper>
        </section>
      </div>

    </b-modal>
    <b-pagination
        :current="page"
        :order="'is-centered'"
        :per-page="limit"
        :range-after="2"
        :range-before="2"
        :total="meta.total"
        aria-current-label="Current page"
        aria-next-label="Next page"
        aria-page-label="Page"
        aria-previous-label="Previous page"
        class="mt-4"
        v-on:change="setPage"
    >


    </b-pagination>
  </div>
</template>

<script>

import HomeClass from "@/models/HomeClass";
import UserProfile from "@/components/users/UserProfile";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "Service.index",
  components: {PerfectScrollbarWrapper},
  data() {
    return {
      loadingFilter: false,
      classesDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      loaded: false,
      searchHold: null,
      modal_open: false,
      activeLearners: []
    };
  },

  methods: {


    showLearner(user) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          type: 'Learner',
          tabs: [
            {title: 'User Details', role: 'details'},
            {title: 'Profile', role: 'profile'},
            {title: 'Enrolments', role: 'enrolments'},
            {title: 'Guardians', role: 'guardians'},
            {title: 'Services', role: 'services'},
            {title: 'Notes', role: 'notes'},
            ...(this.$store.getters['entities/user-permissions/find']('view media') ? [{
              title: 'Media',
              role: 'media'
            }] : []),            {title: 'Observations', role: 'observations'},
            {title: 'Activities', role: 'activities'},

          ], profileFields:
              [
                'date_of_birth',
                'id',
                'gender',
                'language',
                'cemis_number', 'school',
                'grade',
                'dominant_hand',
                'ethnicity',
                'home_address', 'siblings', 'religion', 'medical_information', 'emergency_contact', 'consent', 'lunch', 'day_length'
              ],
          proppedUser: user,
          canEdit: !!this.$store.getters["entities/user-permissions/find"](
              "edit learners"
          ),
          canDelete: !!this.$store.getters["entities/user-permissions/find"](
              "delete learners"
          ),
          canCreateEnrolment: !!this.$store.getters['entities/user-permissions/find']('create enrolments'),
          canDeleteEnrolment: !!this.$store.getters['entities/user-permissions/find']('delete enrolments')
          ,
          canEditEnrolment: !!this.$store.getters['entities/user-permissions/find']('edit enrolments')

          , canCreateSubject:
              !!this.$store.getters['entities/user-permissions/find']('create subjects')

          , canDeleteSubject:
              !!this.$store.getters['entities/user-permissions/find']('delete subjects')

          , canEditSubject:
              !!this.$store.getters['entities/user-permissions/find']('edit subjects')

          , canCreate:
              !!this.$store.getters['entities/user-permissions/find']('create learners')

          , canCreateGuardian:
              !!this.$store.getters['entities/user-permissions/find']('edit learners')

          , canDeleteGuardian:
              !!this.$store.getters['entities/user-permissions/find']('edit learners')

          , canEditGuardian:
              !!this.$store.getters['entities/user-permissions/find']('edit learners')

          ,
          canCreateWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')

          ,
          canDeleteWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')

          ,
          canEditWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')

          ,
          canCreateLearner: !!this.$store.getters['entities/user-permissions/find']('create learners')
        },
        component: UserProfile,
        fullScreen: true,
        trapFocus: true,
      })
    },
    viewLearners(row) {

      this.$store.dispatch('loader/show')
      HomeClass.FetchById(row.id, ['users:enrolled']).then(() => {
        this.activeLearners = HomeClass.query().whereId(row.id).with('users').first().users
        this.modal_open = true

        this.$store.dispatch('loader/hide')
        setTimeout(()=>{
          document.getElementsByClassName('animation-content')[0].classList.add("is-large");

        },500)

      }).catch((err) => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')
      })
    },
    downloadPdf(homeClass) {
      this.$store.dispatch('loader/show')
      HomeClass.Download(homeClass.id).then(({response}) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `${homeClass.name} list.pdf`
        );

        document.body.appendChild(link);
        link.click();
        this.$store.dispatch('loader/hide')

      }).catch((err) => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')
      })
    },
    setPage(pageNumber) {
      this.loadingData = true;
      this.classesDataHold = this.services;
      HomeClass.deleteAll();
      this.page = pageNumber;
      HomeClass.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters,
          []
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingData = false;
            this.classesDataHold = null;
            this.$store.dispatch("loader/hide")
            this.loaded = true
          }
      );
    },
  },
  watch: {
    filters() {
      this.setPage(this.page);
    },
  },
  computed: {
    filters() {
      return {
        ...(this.search
            ? {
              search: this.search,
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {}), year: this.$store.state.dates.year
      };
    },
    classes() {
      if (this.classesDataHold === null) {
        return HomeClass.query().with("users").orderBy('name').get();
      } else {
        return this.classesDataHold;
      }
    },
  },
  mounted() {
    this.$store.dispatch('loader/show')
    this.setPage(this.page, true);


  },
};
</script>
